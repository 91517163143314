import React, { useState } from "react";
import aboutHome from "../../assets/images/about/about-home.png";
import ourWorking1 from "../../assets/images/process/1.png";
import ourWorking2 from "../../assets/images/process/2.png";
import ourWorking3 from "../../assets/images/process/3.png";
import ourWorking4 from "../../assets/images/process/4.png";
import videoImage from "../../assets/images/video/video.jpg";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer correctly
import { InsertDataofContactPage } from '../../services/website/website'; // Adjust the import path accordingly



function AboutPage() {
  // Define state variables for form inputs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [website, setWebsite] = useState("");
  const navigate = useNavigate();

  const [ourWorkingList, setOurWorkingList] = useState([
    {
      id: 1,
      img: ourWorking1,
      name: "Discovery",
    },
    {
      id: 2,
      img: ourWorking2,
      name: "Planning",
    },
    {
      id: 3,
      img: ourWorking3,
      name: "Execute",
    },
    {
      id: 3,
      img: ourWorking4,
      name: "Delievr",
    },
  ]);

  const steps = [
    {
      id: 1,
      image: ourWorking1,
      title: "1. Discussion & Brainstorming",
      description:
        "At EconSign Soft, we emphasize the importance of thorough discussions and brainstorming sessions to ensure the success of every project. Our brainstorming process is deeply rooted in software research, where we analyze the latest trends, tools, and technologies to identify the most effective solutions. During these sessions, our team collaborates to explore innovative ideas, evaluate feasibility, and align the proposed solutions with our clients' business objectives. By leveraging in-depth research and collective expertise, we aim to develop strategies that address challenges effectively, enhance user experience, and deliver cutting-edge software solutions tailored to meet unique requirements.",
    },
    {
      id: 2,
      image: ourWorking2,
      title: "2. Planning & Prototyping",
      description:
        "At EconSign Soft, meticulous planning and prototyping form the foundation of our development process. During the planning phase, we outline clear project goals, define timelines, allocate resources, and establish a roadmap to ensure seamless execution. This stage is supported by extensive research and analysis to ensure every detail aligns with our clients' vision and business objectives.",
    },
    {
      id: 3,
      image: ourWorking3,
      title: "3. Execution",
      description:
        "The execution phase at EconSign Soft is where plans and prototypes come to life. Our team of skilled developers and engineers leverages cutting-edge tools, technologies, and best practices to build robust and scalable software solutions. We follow a structured and agile development methodology to ensure flexibility, transparency, and timely delivery of each project milestone.",
    },
    {
      id: 4,
      image: ourWorking4,
      title: "4. Delivery & Deployment",
      description:
        "At EconSign Soft, the Delivery & Deployment phase is the culmination of our development journey, where the final product is prepared for launch and handed over to the client. Before delivery, the software undergoes rigorous testing, including performance, security, and usability assessments, to ensure it meets the highest standards of quality and reliability.",
    },
  ];

  // Define state variables for error messages
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    website: "",
  });

  // Define loading state for the button animation
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    // Redirect to the YouTube video when the button is clicked
    window.location.href = "https://www.youtube.com/watch?v=uqZPq6mZhSI";
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Validation logic
    let formValid = true;
    let formErrors = {
      name: "",
      email: "",
      phone: "",
      message: "",
      website: "",
    };

    // Email regex pattern to validate format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if any fields are empty
    if (!name) {
      formErrors.name = "Please enter your Name";
      formValid = false;
    }
    if (!email) {
      formErrors.email = "Please enter Email";
      formValid = false;
    } else if (!emailRegex.test(email)) {
      formErrors.email = "Please enter a valid Email address";
      formValid = false;
    }
    if (!website) {
      formErrors.website = "Please enter Website";
      formValid = false;
    }
    if (!phone || phone.length !== 10) {
      formErrors.phone = "Please enter a valid 10-digit Phone Number";
      formValid = false;
    }
    if (!message) {
      formErrors.message = "Please enter Message";
      formValid = false;
    }

    // Set the error messages in state
    setErrors(formErrors);

    if (formValid) {
      // Start the loading effect
      setIsLoading(true);

      const formData = {
        Name: name,
        Email: email,
        Mobile: phone,
        Message: message,
        Website: website,
      };

      // Simulate a 3-second delay
      setTimeout(async () => {
        try {
          const response = await InsertDataofContactPage(formData);
          console.log("Form submitted successfully:", response);
          toast.success("Your request has been sent successfully"); // Show success toast

          // Clear the form fields
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setWebsite("");
        } catch (error) {
          console.error("Error submitting form:", error);
          toast.error("Error submitting the form. Please try again!"); // Show error toast
        } finally {
          // Stop the loading effect once the form submission is complete
          setIsLoading(false);
        }
      }, 1000); // 1-second delay
    } else {
      toast.error("Please fill in all fields correctly!"); // Show error toast if form is not valid
    }
  };

  const handlePhoneChange = (e) => {
    // Allow only numbers and ensure the length doesn't exceed 10
    let value = e.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  return (
    <>
      <div className="about">
        <div className="about-heading">
          <h1 htmlFor="">About</h1>
          <label htmlFor="">
            <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              Home
            </span>{" "}
            / About
          </label>
        </div>

        <div className="about-us">
          <div className="about-us-icon">
            <img src={aboutHome} alt="" />
          </div>
          <div className="about-us-description">
            <a htmlFor="" className="small-heading">
              about us
            </a>
            {/* <h1 className="big-heading">
              We Are Increasing Business
              <br /> Success With Technology
            </h1> */}
            <p className="thick-paragraph">
              <strong style={{ fontSize: "30px" }}>EconSign Soft</strong> is a
              renowned software development company dedicated to delivering
              end-to-end IT solutions to businesses across the globe. With over
              a decade of expertise, we have been a trusted partner for
              organizations striving to achieve digital excellence. Our approach
              combines innovation, quality, and reliability to create tailored
              digital solutions that address the unique challenges of modern
              businesses. By leveraging next-generation technologies, we empower
              companies to enhance their operational efficiency, drive growth,
              and stay ahead in the competitive market. At EconSign Soft, we
              believe in fostering strong partnerships and delivering
              value-driven results. Our team of experienced professionals is
              committed to transforming your vision into reality through
              cutting-edge technology and creative problem-solving.
            </p>
            {/* <p className="thin-paragraph">
              EconSign Soft is a global information technology and consulting
              company have a well-established presence in India. We ensure the
              highest levels of certainty and satisfaction through a deep-set
              commitment to clients with our technology expertise, comprehensive
              portfolio of services and vertically aligned business model. We
              are committed to providing innovative web and mobile application
              development services to clients that not only create tremendous
              value, but provide an impressive return on investment.
            </p> */}
            <button
              className="learn-more-btn"
              onClick={() => navigate("/contact-us")}
            >
              Learn-More
            </button>
          </div>
        </div>
        <div className="steps-container">
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`step-item ${index % 2 === 0 ? "left" : "right"}`}
            >
              <div className="step-content">
                <img src={step.image} alt={step.title} className="step-image" />
              </div>
              <div className="step-sub-content">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="steps-container-mobile">
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`step-item ${index % 2 === 0 ? "left" : "right"}`}
            >
              <div className="step-content">
                <img src={step.image} alt={step.title} className="step-image" />
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
        {/* ************************** Request A Free Consultation ***************************** */}
        <div className="request-consultation">
          <div className="container">
            <div className="request-title">
              <div className="title">
                <a htmlFor="" className="small-heading">
                  Contact
                </a>
              </div>
              <h2>
                Request A Free <br /> Consultation
              </h2>
            </div>
          </div>
          <div className="request-container">
            <div className="request-box">
              <div className="video-box">
                <img src={videoImage} alt="" />
                {/* <div className="playbtn">
                  <div className="playtri"></div>
                </div> */}
              </div>
              <div className="content-box">
                <div className="form-container">
                  <div className="form-input-container">
                    <input
                      type="text"
                      placeholder="Name"
                      value={name}
                      style={
                        !name && errors.name
                          ? { border: "1px solid #DC4C64" }
                          : undefined
                      }
                      onChange={(e) => setName(e.target.value)}
                    />
                    {!name && errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                  <div className="form-input-container">
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      style={
                        !email && errors.email
                          ? { border: "1px solid #DC4C64" }
                          : undefined
                      }
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {!email && errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-input-container">
                    <input
                      type="tel"
                      placeholder="Phone"
                      value={phone}
                      style={
                        !phone && errors.phone
                          ? { border: "1px solid #DC4C64" }
                          : undefined
                      }
                      onChange={handlePhoneChange}
                    />
                    {!phone && errors.phone && (
                      <span className="error">{errors.phone}</span>
                    )}
                  </div>
                  <div className="form-input-container">
                    <input
                      placeholder="Message"
                      value={message}
                      style={
                        !message && errors.message
                          ? { border: "1px solid #DC4C64" }
                          : undefined
                      }
                      onChange={(e) => setMessage(e.target.value)}
                    ></input>
                    {!message && errors.message && (
                      <span className="error">{errors.message}</span>
                    )}
                  </div>
                  <div className="form-input-container">
                    <input
                      placeholder="Website"
                      value={website}
                      style={
                        !website && errors.website
                          ? { border: "1px solid #DC4C64" }
                          : undefined
                      }
                      onChange={(e) => setWebsite(e.target.value)}
                    ></input>
                    {!website && errors.website && (
                      <span className="error">{errors.website}</span>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className={`submit-btn ${isLoading ? "loading" : ""}`}
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default AboutPage;
