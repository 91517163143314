import { userInstance as userInstance } from "../../config/baseurl";

export const Login = async (data) => {
  return(
    await userInstance.post(`/login`, {
      ...data,
    })
  );
};
export const InsertDataofHomePage = async (data) => {
    return(
      await userInstance.post(`/insert-data-of-homepage`, {
        ...data,
      })
    );
  };

export const InsertDataofContactPage = async (data) => {
    return(
      await userInstance.post(`/insert-data-of-contactpage`, {
        ...data,
      })
    );
  };