import React from "react";
import "./index.css";
import logo from "assets/images/eLogo1.png";
import imogi from "assets/images/Sad-Face-Emoji.png";
import { Link, useNavigate } from "react-router-dom";

export default function NoPageFound() {
  const navigate = useNavigate();

  return (
    <>
      <div className="no-page-found">
        <div className="no-found-container">
          <div className="logo">
            <img src={logo} style={{ width: "250px" }} alt="" />
          </div>
          <div className="sad-imoji">
            <img src={imogi} style={{ width: "100px" }} alt="" />
          </div>
          <div className="no-page-error">
            <p>OOPS! No Page Found</p>
          </div>
        </div>
        <div className="no-found-button">
          <button onClick={()=>navigate(-1)} >  Back</button>
          {/* <Link to="https://poki.com/en/g/subway-surfers" style={{color:"red"}}>back</Link> */}
        </div>
      </div>
    </>
  );
}
