import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InsertDataofContactPage } from "../../services/website/website"; // Adjust the import path accordingly
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer correctly

function ContactPage() {
  // Define state variables for form inputs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [website, setWebsite] = useState("");
  const navigate = useNavigate();

  // Define state variables for error messages
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    website: "",
  });

  // Define loading state for the button animation
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    // Redirect to the YouTube video when the button is clicked
    window.location.href = "https://www.youtube.com/watch?v=uqZPq6mZhSI";
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Validation logic
    let formValid = true;
    let formErrors = {
      name: "",
      email: "",
      phone: "",
      message: "",
      website: "",
    };

    // Email regex pattern to validate format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if any fields are empty
    if (!name) {
      formErrors.name = "Please enter your Name";
      formValid = false;
    }
    if (!email) {
      formErrors.email = "Please enter Email";
      formValid = false;
    } else if (!emailRegex.test(email)) {
      formErrors.email = "Please enter a valid Email address";
      formValid = false;
    }
    if (!website) {
      formErrors.website = "Please enter Website";
      formValid = false;
    }
    if (!phone || phone.length !== 10) {
      formErrors.phone = "Please enter a valid 10-digit Phone Number";
      formValid = false;
    }
    if (!message) {
      formErrors.message = "Please enter Message";
      formValid = false;
    }

    // Set the error messages in state
    setErrors(formErrors);

    if (formValid) {
      // Start the loading effect
      setIsLoading(true);

      const formData = {
        Name: name,
        Email: email,
        Phone: phone,
        Message: message,
        Website: website,
      };

      // Simulate a 3-second delay
      setTimeout(async () => {
        try {
          const response = await InsertDataofContactPage(formData);
          if (response.data.code == 200) {
            toast.success(response.data.message); // Show success toast
            // Clear the form fields
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
            setWebsite("");
          } else {
            toast.error(response.data.message); // Show success toast
            // Clear the form fields
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
            setWebsite("");
          }
        } catch (error) {
          console.error("Error submitting form:", error);
          toast.error("Error submitting the form. Please try again!"); // Show error toast
        } finally {
          // Stop the loading effect once the form submission is complete
          setIsLoading(false);
        }
      }, 1000); // 1-second delay
    } else {
      toast.error("Please fill in all fields correctly!"); // Show error toast if form is not valid
    }
  };

  const handlePhoneChange = (e) => {
    // Allow only numbers and ensure the length doesn't exceed 10
    let value = e.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setPhone(value);
    }
  };
  return (
    <>
      <div className="about">
        <div className="contact-heading">
          <h1 style={{ color: "#000" }} htmlFor="">
            Contact
          </h1>
          <label htmlFor="" style={{ color: "#000" }}>
            {" "}
            <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              Home
            </span>{" "}
            / Contact
          </label>
        </div>
        <div className="fill-the-form-below">
          <div className="container">
            <div className="left-container">
              <div className="title-container">
                <label htmlFor="">let's talk</label>
                <h2 className="title">Speak with expert engineers</h2>
              </div>
              <div className="address-box">
                <div className="address-icon">
                  <FontAwesomeIcon icon={faHome} className="icon" />
                </div>
                <div className="address-text">
                  <span className="label">Email :</span>
                  <Link to={"support@econsignsoft.com"}>
                    support@econsignsoft.com
                  </Link>
                </div>
              </div>
              <div className="address-box">
                <div className="address-icon">
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                </div>
                <div className="address-text">
                  <span className="label">Phone :</span>
                  <Link to={"support@econsignsoft.com"}>+91 20-67622438</Link>
                </div>
              </div>
              <div className="address-box">
                <div className="address-icon">
                  <FontAwesomeIcon icon={faLocationPin} className="icon" />
                </div>
                <div className="address-text">
                  <span className="label">Address :</span>
                  <div className="desc" to={"support@econsignsoft.com"}>
                    Kondhwa, Pune-411060 India
                  </div>
                </div>
              </div>
            </div>
            <div className="right-container">
              <div className="title-container2">
                <label className="contact-sub-text">Get In Touch</label>
                <br />
                <br />
                <h2>Fill The Form Below</h2>
              </div>
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-input-container">
                  <input
                    type="text"
                    placeholder="Name"
                    style={
                      !name && errors.name
                        ? { border: "1px solid #DC4C64" }
                        : undefined
                    }
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {!name && errors.name && (
                    <span className="error-message">{errors.name}</span>
                  )}
                </div>
                <div className="form-input-container">
                  <input
                    type="text"
                    placeholder="Email-Id"
                    style={
                      !email && errors.email
                        ? { border: "1px solid #DC4C64" }
                        : undefined
                    }
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {!email && errors.email && (
                    <span className="error-message">{errors.email}</span>
                  )}
                </div>
                <div className="form-input-container">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    style={
                      !phone && errors.phone
                        ? { border: "1px solid #DC4C64" }
                        : undefined
                    }
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                  {!phone && errors.phone && (
                    <span className="error-message">{errors.phone}</span>
                  )}
                </div>
                <div className="form-input-container">
                  <input
                    type="text"
                    placeholder="Your Website"
                    style={
                      !website && errors.website
                        ? { border: "1px solid #DC4C64" }
                        : undefined
                    }
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                  {!website && errors.website && (
                    <span className="error-message">{errors.website}</span>
                  )}
                </div>
                <div className="form-textarea-container">
                  <textarea
                    placeholder="Your Message Here"
                    rows={5}
                    value={message}
                    style={
                      !message && errors.message
                        ? { border: "1px solid #DC4C64" }
                        : undefined
                    }
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  {!message && errors.message && (
                    <span className="error-message">{errors.message}</span>
                  )}
                </div>
                <div className="submit-btn">
                  <button type="submit" disabled={isLoading}>
                    {isLoading ? "Submitting..." : "Submit Now"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="live-location-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15139.171147451823!2d73.8993912!3d18.4477151!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebd31be5f9c7%3A0xb407ae10706907e7!2sEconsign%20Soft%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674721113230!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <ToastContainer />
    </>
  );
}

export default ContactPage;
