import React, { useState } from 'react';
import { InsertDataofHomePage } from '../../services/website/website'; // Adjust the import path accordingly
import Banner from '../../assets/images/banner.jpg';
import './Banner.scss'; // Import the SCSS file
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer correctly
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

function BannerPage() {
    // Define state variables for form inputs
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    // Define state variables for error messages
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    // Define loading state for the button animation
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        // Redirect to the YouTube video when the button is clicked
        window.location.href = "https://www.youtube.com/watch?v=uqZPq6mZhSI";
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
    
        // Validation logic
        let formValid = true;
        let formErrors = {
            name: '',
            email: '',
            phone: '',
            message: ''
        };
    
        // Check if any fields are empty
        if (!name) {
            formErrors.name = "Please enter your Name";
            formValid = false;
        }
        if (!email) {
            formErrors.email = "Please enter Email";
            formValid = false;
        }
        if (!phone || phone.length !== 10) {
            formErrors.phone = "Please enter a valid 10-digit Phone Number";
            formValid = false;
        }
        if (!message) {
            formErrors.message = "Please enter Message";
            formValid = false;
        }
    
        // Set the error messages in state
        setErrors(formErrors);
    
        if (formValid) {
            // Start the loading effect
            setIsLoading(true);
    
            const formData = {
                Name: name,
                Email: email,
                Phone: phone,
                Message: message
            };
    
            // Simulate a 3-second delay
            setTimeout(async () => {
                try {
                    const response = await InsertDataofHomePage(formData);

                    if(response.data.code == 200){
                        toast.success(response.data.message); // Show success toast
                        // Clear the form fields
                        setName('');
                        setEmail('');
                        setPhone('');
                        setMessage('');
                    }else{
                        toast.error(response.data.message); // Show success toast
                        // Clear the form fields
                        setName('');
                        setEmail('');
                        setPhone('');
                        setMessage('');                       
                    }
    
                } catch (error) {
                    console.error("Error submitting form:", error);
                    toast.error("Error submitting the form. Please try again!"); // Show error toast
                } finally {
                    // Stop the loading effect once the form submission is complete
                    setIsLoading(false);
                }
            }, 1000); // 3-second delay
        } else {
            toast.error("Please fill in all fields correctly!"); // Show error toast if form is not valid
        }
    };
    

    const handlePhoneChange = (e) => {
        // Allow only numbers and ensure the length doesn't exceed 10
        let value = e.target.value.replace(/\D/g, '');
        if (value.length <= 10) {
            setPhone(value);
        }
    };

    return (
        <div className="banner-container">
            <div className="overlay"></div>
            <div className="content-wrapper">
                <div className="content">
                    <h1 className="heading">IT Consulting Services For Your Business</h1><br />
                    <h5 className="subheading">
                        We are a leading technology solutions provider doing business worldwide for over 10+ years.
                    </h5><br />
                    <button className="play-button" onClick={handleClick}>
                        <i className="play-icon">▶</i>
                    </button>
                </div>
                <div className="form-box-wrapper">
                    <div className="form-box">
                        <div className="top">
                            <h1>Get Quote</h1>
                            <h3 className="form-subheading">We are here to help you with our experts</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <input
                                    type="text"
                                    className={`inpl ${!name && errors.name && "is-invalid"}`}
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {!name && errors.name && <div className="error-message">{errors.name}</div>}
                            </div>
                            <br />
                            <div>
                                <input
                                    className={`inpl ${!email && errors.email && "is-invalid"}`}
                                    type="email"
                                    placeholder="E-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {!email && errors.email && <div className="error-message">{errors.email}</div>}
                            </div>
                            <br />
                            <div>
                                <input
                                    className={`inpl ${!phone && errors.phone && "is-invalid"}`}
                                    type="tel"
                                    placeholder="Phone Number"
                                    value={phone}
                                    onChange={handlePhoneChange} // Using the updated function
                                />
                                {!phone && errors.phone && <div className="error-message">{errors.phone}</div>}
                            </div>
                            <br />
                            <div>
                                <input
                                    className={`inpl ${!message && errors.message && "is-invalid"}`}
                                    type="text"
                                    placeholder="Message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                {!message && errors.message && <div className="error-message">{errors.message}</div>}
                            </div>
                            <br />
                            <div>
                                <center><button
                                    type="submit"
                                    className={`submit-button ${isLoading ? 'loading' : ''}`} // Add the loading class if isLoading is true
                                    disabled={isLoading} // Disable the button while loading
                                >
                                    {isLoading ? (
                                        <span className="spinner"></span> // Show a loading spinner when isLoading is true
                                    ) : (
                                        <span className="buttontext">Submit Now</span>
                                    )}
                                </button></center>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* Corrected ToastContainer component */}
            <ToastContainer />
        </div>
    );
}

export default BannerPage;
