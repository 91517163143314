export function dateFormat(date) {
    if (date) {
      return new Date(Date.parse(date)).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    } else {
      return "-- --- ----";
    }
    // return moment(date).format("DD/MM/YYYY");
  }
  
  export function reportdateFormat(date) {
    if (date) {
      return new Date(Date.parse(date)).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true, // Use 24-hour format
        timeZone: "Asia/Kolkata" // Adjust according to your specific time zone
      });
    } else {
      return "-- --- ----";
    }
    // return moment(date).format("DD/MM/YYYY");
  }