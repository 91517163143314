import axios from "axios";

export const adminInstance = axios.create({
    baseURL: `https://api.econsignsoft.com/api/v1/admin`,
  });

export const userInstance = axios.create({
    baseURL: `https://api.econsignsoft.com/api/v1/user`,
  });

