import React, { useState } from "react";
import "./Login.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from "../../assets/images/eLogo.png";
import { useNavigate } from "react-router-dom";
import { handleAdminLogin } from "services/login";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { IconButton, Input, InputAdornment } from "@mui/material";
import toast from "react-hot-toast";

const LoginPage = () => {
  const [data, setData] = useState([]);
  const [errorField, setErrorField] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLoginValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!data.UserName) {
      errorField["UserName"] = "Please Enter Username";
      formIsValid = false;
    }

    if (!data.Password) {
      errorField["Password"] = "Please Enter Password";
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };
  const handleLogin = async () => {
    if (handleLoginValidation()) {
      setIsLoading(true);

      // Simulate a 3-second delay
      try {
        const response = await handleAdminLogin(data);
        if (response.data.code == 200) {
          navigate("/admin-home");
          toast.success(response.data.message);
          setData({ UserName: "", Password: "" });
        } else {
          toast.error(response.data.message);
          setData({ UserName: "", Password: "" });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error submitting the form. Please try again!"); // Show error toast
      } finally {
        // Stop the loading effect once the form submission is complete
        setIsLoading(false);
      }
    } else {
      toast.error("Please fill in all fields correctly!");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="brand-logo">
          <img src={logo} style={{ width: "55%" }}></img>
        </div>
        {/* <h2>Welcome Back!</h2> */}
        <p style={{ fontSize: "14px" }}>Please log in to continue</p>
        {/* <form > */}

        <div className="input-group">
          <Input
            type="username"
            placeholder="Username"
            name="UserName"
            onChange={(e) => {
              const { value, name } = e.target;
              setData({ ...data, [name]: value });
            }}
            value={data.UserName}
            className="input-field"
            style={
              !data.UserName && errorField.UserName
                ? { border: "1px solid #DC4C64" }
                : undefined
            }
            startAdornment={
              <InputAdornment position="start">
                <span className="Icon">
                  <i className="fa fa-user"></i>
                </span>
              </InputAdornment>
            }
          />
          {!data.UserName && errorField.UserName && (
            <span className="error-message">{errorField.UserName}</span>
          )}
        </div>
        <div className="input-group">
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            name="Password"
            onChange={(e) => {
              const { value, name } = e.target;
              setData({ ...data, [name]: value });
            }}
            value={data.Password}
            className="input-field"
            style={
              !data.Password && errorField.Password
                ? { border: "1px solid #DC4C64" }
                : undefined
            }
            startAdornment={
              <InputAdornment position="start">
                <span className="Icon">
                  <i className="fa fa-unlock-alt"></i>
                </span>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {!data.Password && errorField.Password && (
            <span className="error-message ">{errorField.Password}</span>
          )}
        </div>

        {/* <div className="input-group">
          <input
            type="username"
            placeholder="Username"
            name="UserName"
            onChange={(e) => {
              const { value, name } = e.target;
              setData({ ...data, [name]: value });
            }}
            value={data.UserName}
            className="input-field"
            style={
              !data.UserName && errorField.UserName
                ? { border: "1px solid #DC4C64" }
                : undefined
            }
          />
          <span className="Icon">
            <i className="fa fa-user"></i>
          </span>
          {!data.UserName && errorField.UserName && (
            <span className="error-message">{errorField.UserName}</span>
          )}
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            name="Password"
            onChange={(e) => {
              const { value, name } = e.target;
              setData({ ...data, [name]: value });
            }}
            value={data.Password}
            className="input-field"
            style={
              !data.Password && errorField.Password
                ? { border: "1px solid #DC4C64" }
                : undefined
            }
          />
          <span className="Icon">
            <i className="fa fa-unlock-alt"></i>
          </span>
          {!data.Password && errorField.Password && (
            <span className="error-message ">{errorField.Password}</span>
          )}
        </div> */}
        <br />
        <button className="login-btn" onClick={handleLogin}>
          Login
        </button>
        <br />
        {/* </form> */}
      </div>
      {/* toast container */}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default LoginPage;
