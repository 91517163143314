import { Button } from "component/Button/Button";
import IconButton from "component/Button/IconButton";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getContactPageFormList } from "services/contact";
import {reportdateFormat} from "../../../src/utils"
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";


export default function ContactUs() {
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);

  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  // ****************************************** Fetch Record ************************************************
  console.log("records",record);
  
  const handleRecords = async () => {
    showLoader();
    try {
      const res = await getContactPageFormList({
        page: activePage,  // Send page as query parameter
        limit: limit,      // Send limit as query parameter

      });
      console.log("res",res);
      
      if (res.data.code == 200) {
        setRecord(res.data.data.contactPageForms.rows);
        
        setTotal(res.data.data.contactPageForms.count || 0);
        toast.success(res.message);
      } else {
        setRecord([]);
        setTotal(0);
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
    hideLoader();
  };
  
  useEffect(() => {
    handleRecords();
  }, [activePage, limit]);

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage.selected + 1); // `selected` starts from 0
  };

  return (
    <>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="search_wrap"></div>
              <h4 className="AdminTitle">Contact Us</h4>
            </div>

            {/*<div className="mt-3">
              <div className="admin-row">
                <div>
                  <input
                    className={"admin-input-control"}
                    placeholder="Search"
                    // value={search}
                    // onChange={({ target }) => setSearch(target.value)}
                    type="text"
                  />
                </div>
                <div>
                  <Button
                    // onClick={handleRecords}
                    {...submitButtonElement}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>

            <hr style={{ background: "red" }} />*/}
            {loader}
            <div className="row mt-3">
              <div className="col-sm-12">
                {/* <h4>All Student List</h4> */}
                <div className="TableList overflow-auto table-responsive p-0">
                  <table className="position-relative">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email </th>
                        <th>Phone</th>
                        <th>Message</th>
                        <th>Date And Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record && record.length > 0 ? (
                        record.map(
                          ({ id, Name, Email, Phone, Message, AddedOn }, i) => (
                            <tr key={i}>
                              <td className="text-center">
                                {++i + (activePage - 1) * limit}
                              </td>
                              <td>{Name}</td>
                              <td>{Email}</td>
                              <td>{Phone}</td>
                              <td>{Message}</td>
                              <td>{reportdateFormat(AddedOn)}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <td
                          colSpan={6}
                          style={{
                            textAlign: "center",
                            padding: "7px 20px",
                            background: "#efefef",
                          }}
                        >
                          Data Not Found
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>
                {total >= 10 && (
                  <div className="Pagination">
                    <ReactPaginate
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      activeClassName={"active"}
                      onPageChange={handlePageChange}
                      pageCount={Math.ceil(total / limit)}
                      breakLabel="..."
                      previousLabel={
                          <MdOutlineKeyboardArrowLeft />
                      }
                      nextLabel={
                          <MdOutlineKeyboardArrowRight />
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
