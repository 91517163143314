import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { MdOutlineMenu, MdOutlineMenuOpen } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import FullLogo from "assets/images/eLogo.png";
import Logo from "assets/images/fav.png";

export default function Header({ fullMenu, setFullMenu }) {
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);
  //   const { removeCookie } = useCookie();
  //   const usertypeid = localStorage.getItem("roleid");

  //***********Handel Logout********** */
  const handelLogout = (e) => {
    e.preventDefault();
    // removeCookie();
    localStorage.removeItem("toggle");
    localStorage.removeItem(process.env.REACT_APP_AUTH);
    navigate("/");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };

  const menuToggle = () => {
    setFullMenu(!fullMenu);
    // setLocalConfig("menu", !fullMenu);
  };

  return (
    <>
      <div className="adminHeader">
        <div className={fullMenu ? "Logo" : "Small-Logo"}>
          <Link to="/dashboard">
            <img src={fullMenu ? FullLogo : Logo} alt="logo" loading="lazy" />
          </Link>
        </div>
        <div className="Navigation">
          <div className="expandable">
            <IconButton aria-label="menu" onClick={menuToggle}>
              {fullMenu ? (
                <MdOutlineMenuOpen color="primary" />
              ) : (
                <MdOutlineMenu color="primary" />
              )}
              {/* <a style={{ color: "#3A69B0", marginLeft: "8px" }}>
                {usertypeid === "12" ? "Hi Mentor!" : ""}
              </a> */}
            </IconButton>
          </div>
          {/* <div className="Avater">
            <Profile />
          </div> */}
        </div>

        <div
          className="d-block d-lg-none d-xl-none px-4"
          onClick={() => setDrawer(true)}
        >
          {/* <i className="fa fa-bars open"></i> */}
        </div>
      </div>
      {/* <MobileMenu
        toggleDrawer={toggleDrawer}
        drawer={drawer}
        onClose={() => setDrawer(false)}
        handelLogout={handelLogout}
      /> */}
    </>
  );
}
