import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import Routing from 'Routes/Routing';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routing/>
        <Toaster position="top-right" />
      </BrowserRouter>
    </>
  );
}

export default App;
