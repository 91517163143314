import {adminInstance as adminInstance } from "config/baseurl";

export const getContactPageFormList = async (data) => {
  return await adminInstance.get(
    `/getContactPageFormList`,{
      params: { reqData: data }
    }
  );
};

