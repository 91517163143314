import { Collapse } from '@mui/material';
import ModalBox from 'component/modal/ModalBox';
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { sidebar } from 'Routes/Routing';
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function Sidebar({fullMenu}) {
  const history = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  // const { removeCookie } = useCookie();
  // const { roles } = useAdminAuth();
  // const [style, ddToggler] = useDropdownToggler();


  const handelLogout = (e) => {
    history("/admin/login");
  };
  return (
    <>
      <div className={fullMenu ? "AdminSidenavBar" : "AdminSidenavBar-Small"}>
        <ul>
          {sidebar.map(({ path, icon, name, isChild }) => {
            return isChild ? (
              <SubMenu
                isChild={isChild}
                icon={icon}
                path={path}
                name={name}
                key={path}
                // style={style}
                fullMenu={fullMenu}
                // toggle={() => ddToggler(path)}
              />
            ) : (
              <li className="" key={path}>
                <NavLink to={path}>
                  <span>{icon}</span>
                  {fullMenu ? name : <>&nbsp;</>}
                </NavLink>
              </li>
            );
          })}
          <li>
            <a onClick={() => setLogoutModal(true)}>
              <span>
                {/* <i className="fa fa-sign-out"></i> */}
                {/* <LogoutIcon fontSize="small" /> */}
              </span>
              {fullMenu ? "Logout" : <>&nbsp;</>}
            </a>
          </li>
        </ul>
      </div>

      {/* logout modal */}

      <ModalBox
        status={logoutModal}
        onClose={() => setLogoutModal(false)}
        title={"Logout"}
        description={"Are you sure want to logout ?"}
        action={handelLogout}
      />
    </>
  )
}


const SubMenu = ({
  isChild,
  icon,
  name,
  onClose,
  path,
  style,
  toggle,
  fullMenu,
}) => {
  // const [open, setOpen] = useState(false);

  return (
    <>
      <li>
        <a onClick={toggle}>
          <span>
            {/* <i className={icon}></i> */}
            {icon}
          </span>
          {fullMenu ? name : <>&nbsp;</>}
          {fullMenu && (
            <label>
              <i
                className={
                  style === path ? "fa fa-caret-down" : "fa fa-caret-right"
                }
              ></i>
            </label>
          )}
        </a>
        <Collapse in={style === path} timeout="auto" unmountOnExit>
          <div className="sub-menu">
            <ul>
              {isChild &&
                isChild
                  .filter((child) => child.enabled)
                  .map((child) => {
                    return (
                      <li className="" key={child.path}>
                        <NavLink
                          to={child.path}
                          onClick={() => onClose && onClose(false)}
                        >
                          <span>
                            {/* <i className={child.icon}></i> */}
                            {/* <NavigateNextIcon fontSize="small" /> */}
                          </span>
                          {fullMenu ? child.name : <>&nbsp;</>}
                        </NavLink>
                      </li>
                    );
                  })}
            </ul>
          </div>
        </Collapse>
      </li>
    </>
  );
};
