import Footer from "component/Common/Footer";
import Header from "component/Common/Header";
import AdminHeader from "component/AdminCommon/Header";
import About from "page/About/About";
import LoginPage from "adminPages/Login/Login";
import Contact from "page/Contact/Contact";
import Home from "page/Home/Home";
import AppDevelopment from "page/Services/AppDevelopment";
import FullStackDevelopment from "page/Services/FullStackDevelopment";
import OurServices from "page/Services/OurServices";
import SoftwareDevelopment from "page/Services/SoftwareDevelopment";
import UIUXDesign from "page/Services/UIUXDesign";
import WebDevelopment from "page/Services/WebDevelopment";
import { Outlet, Route, Routes } from "react-router-dom";
import React, { lazy, useState } from "react";
import Sidebar from "component/AdminCommon/Sidebar";
import "assets/style/style.css";
import { Skeleton } from "@mui/material";
import ContactUs from "adminPages/ContactUs/ContactUs";
import NoPageFound from "page/NoPageFound/NoPageFound";

const AdminHome = lazy(() => import("adminPages/Home/Home"));

export default function Routing() {
  // Wrapper for Header and Outlet
  const Heading = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  };

  const AdminHeading = () => {
    const [fullMenu, setFullMenu] = useState(true);
    return (
      <>
        <div>
          <AdminHeader fullMenu={fullMenu} setFullMenu={setFullMenu} />
          <Sidebar fullMenu={fullMenu} />
          <React.Suspense
            fallback={
              <div className={fullMenu ? "WrapperArea" : "WrapperArea-Small"}>
                <div className="WrapperBox">
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={"100vh"}
                    animation="wave"
                  />
                </div>
              </div>
            }
          >
            <div
              className={
                fullMenu ? "AdminWrapperArea" : "AdminWrapperArea-Small"
              }
            >
              <Outlet />
            </div>
          </React.Suspense>
        </div>
      </>
    );
  };

  return (
    <Routes>
      <Route path="/*" element={<NoPageFound/>}/>
      {/* Wrap all child routes with the Heading layout */}
      <Route element={<Heading />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />{" "}
        {/* Replace with actual ContactUs component */}
        <Route path="/contact-us" element={<Contact />} />{" "}
        {/* Replace with actual ContactUs component */}
        <Route
          path="/services/web-development"
          element={<WebDevelopment />}
        />{" "}
        {/* Replace with actual ContactUs component */}
        <Route
          path="/services/software-development"
          element={<SoftwareDevelopment />}
        />{" "}
        {/* Replace with actual ContactUs component */}
        <Route
          path="/services/full-stack-development"
          element={<FullStackDevelopment />}
        />{" "}
        {/* Replace with actual ContactUs component */}
        <Route
          path="/services/app-development"
          element={<AppDevelopment />}
        />{" "}
        {/* Replace with actual ContactUs component */}
        <Route path="/services/ui-ux-designs" element={<UIUXDesign />} />{" "}
        {/* Replace with actual ContactUs component */}
        <Route path="/services/our-services" element={<OurServices />} />{" "}
        {/* Replace with actual ContactUs component */}
      </Route>
      <Route path="/admin/login" element={<LoginPage />} />{" "}
      {/* Replace with actual ContactUs component */}
      {/* **************************************** Admin Dashboard ************************************* */}
      <Route element={<AdminHeading />}>
        <Route path="/admin-home" element={<AdminHome />} />
        <Route path="/admin-contact-us" element={<ContactUs />} />
      </Route>
    </Routes>
  );
}

export const sidebar = [
  {
    id: 890003,
    name: "Home",
    // icon: <HistoryEduIcon fontSize="small" />,
    path: "/admin-home",
  },
  {
    id: 890003,
    name: "Contact Us",
    // icon: <HistoryEduIcon fontSize="small" />,
    path: "/admin-contact-us",
  },
];
