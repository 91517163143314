import MuiButton from "@mui/material/Button";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
// import { useAdminAuth } from "context/context";

export const Button = ({ children, to, loading, type, role, ...rest }) => {
  // const { roles } = useAdminAuth();
  return (
    <>
      {/* if type is acl then we have to check role based auth then return data */}
      {type === "acl" && (
        <>
          {/* If link */}
          {to ? (
            <Link to={to} {...rest}>
              <MuiButton
                // color="error"
                variant="outlined"
                {...rest}
              >
                {children}
              </MuiButton>
            </Link>
          ) : loading ? (
            <MuiButton
              // color="error"
              variant="outlined"
              startIcon={<CircularProgress size={20} color="inherit" />}
              {...rest}
            >
              {children}
            </MuiButton>
          ) : (
            <MuiButton
              // color="error"
              variant="outlined"
              {...rest}
            >
              {children}
            </MuiButton>
          )}
        </>
      )}

      {/* if type is not acl return normal button */}
      {(type !== "acl" || type === "submit") && (
        <>
          {/* If link */}
          {to ? (
            <Link to={to} {...rest}>
              <MuiButton
                // color="error"
                variant="outlined"
                {...rest}
              >
                {children}
              </MuiButton>
            </Link>
          ) : loading ? (
            <MuiButton
              // color="error"
              variant="outlined"
              startIcon={<CircularProgress size={20} color="inherit" />}
              {...rest}
            >
              {children}
            </MuiButton>
          ) : (
            <MuiButton
              // color="error"
              variant="outlined"
              type={type}
              {...rest}
            >
              {children}
            </MuiButton>
          )}
        </>
      )}

      {/* if loading is true */}
      {/* {loading && (
        <MuiButton
          color="error"
          variant="outlined"
          startIcon={<CircularProgress size={20} color="inherit" />}
          {...rest}
        >
          {children}
        </MuiButton>
      )} */}
    </>
  );
};
