import React, { useState } from 'react';
import ContactUsImage from '../../assets/images/call-us/contact-here.png';
import CallIcon from '../../assets/images/telephone.png';
import './LetsTalk.scss';
import { useNavigate } from "react-router-dom";


function LetsTalk() {
    const navigate = useNavigate();

    const [hasHovered, setHasHovered] = useState(false); // Track if hover animation has been triggered

    const handleClick = () => {
        window.open("tel:+912067622438", "_self"); // Initiates the call
    };

    return (
        <div>
            <div className="lets-talk-container">
                <div
                    className="lets-talk-banner-container"
                    onMouseEnter={() => setHasHovered(true)}  // Trigger animation once on hover
                >
                    <div className="lets-talk-overlay"></div>
                    <div className="lets-talk-content-wrapper">
                        {/* Left side: Image */}
                        <div className="lets-talk-image-container">
                            <img src={ContactUsImage} alt="Contact Us" className="lets-talk-contact-us-image" />
                        </div>

                        {/* Right side: Text */}
                        <div className="lets-talk-text-container">
                            <div className="call-icon-wrapper">
                                <span className="call-ripple"></span>
                                <img src={CallIcon} alt="Call Us" className="call-icon" />
                            </div>
                            <p className="sec-title3">CALL US</p>
                            <p className="sec-title4">(+91) 20-67622438</p>
                            <p className="sec-title5">
                                Have any idea or project in your mind? Call us or schedule an appointment.
                                Our representative will reply shortly.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Card positioned outside */}
                <div className="lets-talk-card">
                    <div className="lets-talknumber-container">
                        <div className="counter-list">
                            <div className="counter-text">
                                <div className="count-number">
                                    <span className={`rs-count ${hasHovered ? 'animate' : ''}`}>20+</span>
                                </div>
                                <h3 className="title">Happy Clients</h3>
                            </div>
                        </div>
                        <div className="counter-list">
                            <div className="counter-text">
                                <div className="count-number">
                                    <span className={`rs-count ${hasHovered ? 'animate' : ''}`}>10+</span>
                                </div>
                                <h3 className="title">Companies</h3>
                            </div>
                        </div>
                        <div className="counter-list">
                            <div className="counter-text">
                                <div className="count-number">
                                    <span className={`rs-count ${hasHovered ? 'animate' : ''}`}>80+</span>
                                </div>
                                <h3 className="title">Projects Done</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="working-process-container">
                <div className="left-section">
                    <p className='text-working-process'>Working Process</p>
                    <p className="lets-talk-section-title">Our Working Process - How We Work For Our Customers</p>
                    <button className="lets-talk-btn" onClick={() => navigate("/contact-us")}>Contact Us</button>
                </div>

                <div className="right-section">
                    <div className="step">
                        <h3 className="step-title">1. Discussion & Brainstorming</h3>
                        <p className="step-description">
                        At EconSign Soft, we emphasize the importance of thorough discussions and brainstorming sessions to ensure the success of every project. Our brainstorming process is deeply rooted in software research, where we analyze the latest trends, tools, and technologies to identify the most effective solutions. During these sessions, our team collaborates to explore innovative ideas, evaluate feasibility, and align the proposed solutions with our clients' business objectives. By leveraging in-depth research and collective expertise, we aim to develop strategies that address challenges effectively, enhance user experience, and deliver cutting-edge software solutions tailored to meet unique requirements.                        </p>
                    </div>
                    <div className="step">
                        <h3 className="step-title">2. Planning & Prototyping</h3>
                        <p className="step-description">
                        At EconSign Soft, meticulous planning and prototyping form the foundation of our development process. During the planning phase, we outline clear project goals, define timelines, allocate resources, and establish a roadmap to ensure seamless execution. This stage is supported by extensive research and analysis to ensure every detail aligns with our clients' vision and business objectives.                      </p>
                    </div>
                    <div className="step">
                        <h3 className="step-title">3. Execution</h3>
                        <p className="step-description">
                        The execution phase at EconSign Soft is where plans and prototypes come to life. Our team of skilled developers and engineers leverages cutting-edge tools, technologies, and best practices to build robust and scalable software solutions. We follow a structured and agile development methodology to ensure flexibility, transparency, and timely delivery of each project milestone.
                        </p>
                    </div>
                    <div className="step">
                        <h3 className="step-title">4. Delivery & Deployment</h3>
                        <p className="step-description">
                        At EconSign Soft, the Delivery & Deployment phase is the culmination of our development journey, where the final product is prepared for launch and handed over to the client. Before delivery, the software undergoes rigorous testing, including performance, security, and usability assessments, to ensure it meets the highest standards of quality and reliability.                        </p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LetsTalk;
